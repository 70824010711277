import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension";
// thunk middleware is responsible for
// the async action when we fetch data
import thunk from "redux-thunk";

import createRootReducer from "./reducers";

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      process.env.NODE_ENV === "development"
        ? composeWithDevTools(
            applyMiddleware(
              routerMiddleware(history), // for dispatching history actions
              thunk
            )
          )
        : applyMiddleware(
            routerMiddleware(history), // for dispatching history actions
            thunk
          )
    )
  );

  return store;
}
