import { ConnectedRouter } from "connected-react-router";
import * as React from "react";
import { Provider } from "react-redux";
import Router from "./Router";
import configStore, { history as storeHistory } from "./store";
import "./App.css"

const STORE = configStore();

export default class App extends React.Component {
  render() {
    return (
      <Provider store={STORE}>
        <ConnectedRouter history={storeHistory}>
          <Router />
        </ConnectedRouter>
      </Provider>
    );
  }
}
