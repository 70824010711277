import { RECEIVE_USERS } from "../actions/usersActions";

const INIT_STATE = {
  data: []
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case RECEIVE_USERS:
      return { ...state, data: payload };
    default:
      return state;
  }
};
