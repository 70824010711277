import {
  LOGIN_SUCCESS,
  WHOAMI,
  APP_INFO,
  LOGIN_FAILURE
} from "../actions/userActions";

const INIT_STATE = {
  isLogged: false,
  isFetching: true,
  errMsg: null,
  appInfo: {}
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case LOGIN_SUCCESS:
      return { ...state, ...payload, isLogged: true };
    case LOGIN_FAILURE:
      return { ...state, ...payload, isLogged: false };
    case WHOAMI:
      return { ...state, ...payload, isFetching: false };
    case APP_INFO:
      return { ...state, appInfo: payload };
    default:
      return state;
  }
};
