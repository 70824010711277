import React, { Component } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { fetchUsers } from "../actions/usersActions";
import bigLogo from "../images/big_logo.png";
import backend from "../util/backend";
import "./LoginPage.css";

class UsersPage extends Component {
  state = {
    isCreateModal: false,
    isModalOpen: false,
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    errMsg: null
  };

  componentDidMount() {
    this.props.fetchUsers();
  }

  setEditData = data => {
    this.setState({
      ...data.original,
      password: null,
      errMsg: null,
      isCreateModal: false
    });
    this.openModal();
  };

  editUser = e => {
    e.preventDefault();
    const { id, firstName, lastName, email, password } = this.state;
    backend()
      .post("admin/user/edit", {
        id,
        firstName,
        lastName,
        email,
        password
      })
      .then(u => {
        this.props.fetchUsers();
        this.closeModal();
      })
      .catch(e =>
        this.setState({
          errMsg: "Възникна неочакван проблем. Моля опитайте по-късно."
        })
      );
  };

  setCreateData = () => {
    this.setState({
      isCreateModal: true,
      id: null,
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      errMsg: null
    });
    this.openModal();
  };

  createUser = e => {
    e.preventDefault();
    const { firstName, lastName, email, password } = this.state;
    backend()
      .post("admin/user/create", {
        firstName,
        lastName,
        email,
        password
      })
      .then(u => {
        this.props.fetchUsers();
        this.closeModal();
      })
      .catch(e =>
        this.setState({
          errMsg: "Възникна неочакван проблем. Моля опитайте по-късно."
        })
      );
  };

  getColumns = () => [
    {
      Header: "Име",
      accessor: "firstName"
    },
    {
      Header: "Фамилия",
      accessor: "lastName"
    },
    {
      Header: "Имейл",
      accessor: "email",
      Cell: ({ value }) => <a href={`mailto:${value}`}>{value}</a>
    },
    {
      Header: "Действия",
      accessor: "actions",
      filterable: false,
      Cell: c => (
        <button
          className="login-submit"
          style={{ height: 30, float: "left" }}
          onClick={_ => this.setEditData(c)}
        >
          Промяна
        </button>
      )
    }
  ];

  openModal = () => this.setState({ isModalOpen: true });

  closeModal = () => this.setState({ isModalOpen: false });

  render() {
    const { firstName, lastName, email, password } = this.state;
    return (
      <>
        <Modal
          isOpen={this.state.isModalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Потребител"
          style={{
            content: {
              maxWidth: 600,
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)"
            }
          }}
        >
          <form
            onSubmit={
              this.state.isCreateModal ? this.createUser : this.editUser
            }
          >
            <input
              required
              className="login-input"
              value={firstName}
              onChange={e => this.setState({ firstName: e.target.value })}
              placeholder="Име"
            />
            <input
              required
              className="login-input"
              value={lastName}
              onChange={e => this.setState({ lastName: e.target.value })}
              placeholder="Фамилия"
            />
            <input
              required
              className="login-input"
              value={email}
              onChange={e => this.setState({ email: e.target.value })}
              type="email"
              placeholder="Имейл"
            />
            <br />
            <div>
              Паролата е задължителна само при създаването на потребител.
            </div>
            <input
              required={this.state.isCreateModal}
              minLength={5}
              className="login-input"
              value={password}
              onChange={e => this.setState({ password: e.target.value })}
              type="password"
              placeholder="Парола"
            />
            <br />
            <div>{this.state.errMsg}</div>
            <br />
            <div>
              <button
                onClick={this.closeModal}
                className="login-submit"
                style={{ marginLeft: "5px" }}
              >
                Назад
              </button>
              <button type="submit" className="login-submit">
                Запази
              </button>
            </div>
          </form>
        </Modal>
        <Link to="/">
          <div className="logo-holder">
          </div>
        </Link>
        <br />
        <div
          className="logo-holder"
          style={{ height: "35px", cursor: "auto", float: "none" }}
        >
          <button
            className="login-submit"
            onClick={this.setCreateData}
            style={{ marginLeft: "5px" }}
          >
            Добави потребител
          </button>
        </div>
        <br />
        <ReactTable
          className="-striped -highlight"
          filterable
          defaultFilterMethod={(filter, row) =>
            row[filter.id] &&
            String(row[filter.id])
              .toLowerCase()
              .includes(filter.value.toLowerCase())
          }
          columns={this.getColumns()}
          data={this.props.users}
          previousText={"Назад"}
          nextText={"Напред"}
          pageText={"Стр."}
          loadingText={"Зарежда се..."}
          noDataText={null}
          ofText={"от"}
          rowsText={"реда"}
        />
      </>
    );
  }
}

export default connect(
  s => ({ users: s.users.data }),
  { fetchUsers }
)(UsersPage);
