import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./LoginPage.css";
import bigLogo from "../images/big_logo.png";
import axios from "axios";

export default class ChangePassword extends Component {
  state = {
    password: null,
    confirmPassword: null,
    changedPass: false,
    errMsg: null
  };

  onSubmit = e => {
    e.preventDefault();
    const { password, confirmPassword } = this.state;
    const params = new URLSearchParams(this.props.location.search);
    const token = params.get("t");
    axios
      .post(
        `/api/user/changePassword`,
        { password, confirmPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(_ => this.setState({ changedPass: true }))
      .catch(_ => this.setState({changedPass: false, errMsg: 'has_error'}))
  };

  getMessage = () => {
    const { password, confirmPassword } = this.state;
    if (password && password.length < 5)
      return "Паролата трябва да е дълга поне 5 символа.";
    if (password && !confirmPassword) return "Моля повторете паролата.";
    if (!password || !confirmPassword) return null;
    if (password !== confirmPassword) return "Паролите трябва да съвпадат.";
  };

  isValidCombo = () => {
    const { password, confirmPassword } = this.state;
    if (password && password.length < 5) return false;
    if (password && !confirmPassword) return false;
    if (!password || !confirmPassword) return false;
    if (password !== confirmPassword) return false;
    return true;
  };

  render() {
    const { password, confirmPassword, changedPass, errMsg } = this.state;
    if (changedPass) {
      return (
        <div>
          <Link to="/login">
            <div className="logo-holder">
            </div>
          </Link>
          <div className="login-strip" />
          <form onSubmit={this.onSubmit} className="login-wrapper">
            <div className="login-input">
              Вие успешно сменихте вашата парола. Може да се влезнете в
              системата от <Link to="/login">тук</Link>
            </div>
          </form>
        </div>
      );
    }

    if (!changedPass && errMsg) {
      return (
        <div>
          <Link to="/login">
            <div className="logo-holder">
            </div>
          </Link>
          <div className="login-strip" />
          <form onSubmit={this.onSubmit} className="login-wrapper">
            <div className="login-input">
              Този линк за смяна на парола е изтекъл. Моля поискайте нов от{" "}
              <Link to="/forgot_password">тук</Link>
            </div>
          </form>
        </div>
      );
    }

    return (
      <div>
        <Link to="/login">
          <div className="logo-holder">
          </div>
        </Link>
        <div className="login-strip" />
        <form onSubmit={this.onSubmit} className="login-wrapper">
          <div className="login-input">
            Моля въведете и повторете новата си парола.
          </div>
          <br />
          <div>Парола</div>
          <input
            className="login-input"
            value={password}
            onChange={e => this.setState({ password: e.target.value })}
            type="password"
            placeholder="Парола"
          />
          <br />
          <div>Повтори парола</div>
          <input
            className="login-input"
            value={confirmPassword}
            onChange={e => this.setState({ confirmPassword: e.target.value })}
            type="password"
            placeholder="Повтори парола"
          />
          <br />
          <div>
            <button
              type="submit"
              className="login-submit"
              disabled={!this.isValidCombo()}
            >
              Смени
            </button>
          </div>
          <br />
          <div>{this.getMessage()}</div>
        </form>
      </div>
    );
  }
}
