import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import userReducer from './userReducer'
import usersReducer from './usersReducer'
import doctorReducer from './doctorReducer';

export default (history) => combineReducers({
  router: connectRouter(history),
  user: userReducer,
  doctor: doctorReducer,
  users: usersReducer
})