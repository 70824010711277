import backend from "../util/backend";

export const FETCH_USERS = "@@users/FETCH_USERS";
export const RECEIVE_USERS = "@@users/RECEIVE_USERS";

export const fetchUsers = () => dispatch => {
  backend()
    .get("admin/user/all")
    .then(payload =>
      dispatch({
        type: RECEIVE_USERS,
        payload
      })
    );
};
