import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./LoginPage.css";
import bigLogo from "../images/big_logo.png";
import { forgotPassword } from "../actions/userActions";

class ForgotPassword extends Component {
  state = {
    email: null,
    requestSent: false
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.forgotPassword(this.state.email);
    this.setState({
      email: null,
      requestSent: true
    });
  };

  render() {
    const { email, requestSent } = this.state;
    return (
      <div>
        <Link to="/login">
          <div className="logo-holder">
          </div>
        </Link>
        <div className="login-strip" />
        {requestSent ? (
          <div className="login-wrapper">
            <div className="login-input">
              На посоченият от вас емайл адрес беше изпратен емайл с линк за
              смяна на парола.
            </div>
          </div>
        ) : (
          <form onSubmit={this.onSubmit} className="login-wrapper">
            <div className="login-input">
              Моля въведете емайл адрес на който да бъде изпратен линк за смяна на
              парола.
            </div>
            <br />
            <input
              className="login-input"
              value={email}
              onChange={e => this.setState({ email: e.target.value })}
              type="email"
              placeholder="Имейл"
            />
            <br />
            <div>
              <button type="submit" className="login-submit">
                Изпрати
              </button>
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default connect(
  s => ({}),
  { forgotPassword }
)(ForgotPassword);
