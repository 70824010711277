import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../actions/userActions";
import "./LoginPage.css";
import bigLogo from "../images/big_logo.png";

class LoginPage extends Component {
  state = {
    email: null,
    password: null
  };

  onSubmit = e => {
    e.preventDefault();
    const { email, password } = this.state;
    this.props.login(email, password);
  };

  render() {
    const { email, password } = this.state;
    return (
      <div>
        <div className="logo-holder">
          
        </div>
        <div className="login-strip">
          <form onSubmit={this.onSubmit} className="login-wrapper">
            <input
              className="login-input"
              value={email}
              onChange={e => this.setState({ email: e.target.value })}
              type="email"
              placeholder="Имейл"
            />
            <input
              className="login-input"
              value={password}
              onChange={e => this.setState({ password: e.target.value })}
              type="password"
              placeholder="Парола"
            />
            <br />
            <div>{this.props.errMsg}</div>
            <br />
            <div>
              <Link to="/forgot_password">Забравена парола ?</Link>
              <button type="submit" className="login-submit">
                Вход
              </button>
            </div>
          </form>
        </div>
        <div className="login-actualization-strip">
          {this.props.actualization ? (
            <div style={{ float: "right" }}>
              Последна актуализация: {this.props.actualization}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect(
  s => ({
    actualization: s.user.appInfo.actualization,
    errMsg: s.user.errMsg
  }),
  { login }
)(LoginPage);
