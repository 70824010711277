import { putInStorage } from "../util/storage";
import { push } from "connected-react-router";
import backend from "../util/backend";

export const WHOAMI = "@@user/WHOAMI";
export const whoami = () => dispatch => {
  backend()
    .get(`user/whoami`)
    .then(user => {
      dispatch({
        type: WHOAMI,
        payload: { ...user, isLogged: true }
      });
      dispatch(push("/"));
    })
    .catch(e => {
      dispatch({
        type: WHOAMI,
        payload: { isLogged: false }
      });
      dispatch(push("/login"));
    });
};

export const LOGIN_SUCCESS = "@@user/LOGIN_SUCCESS";
export const LOGIN_FAILURE = "@@user/LOGIN_FAILURE";

export const login = (email, password) => dispatch => {
  backend()
    .post(`user/login`, { email, password })
    .then(token => {
      putInStorage({
        token: token.token,
        refreshToken: token.refreshToken,
        expiresAt: token.expiresAt
      });
      dispatch(whoami());
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {}
      });
      dispatch(push("/"));
    })
    .catch(e => {
      dispatch({
        type: LOGIN_FAILURE,
        payload: {
          errMsg: "Грешен имейл или парола."
        }
      });
    });
};

export const FORGOT_PASSWORD = "@@user/FORGOT_PASSWORD";

export const forgotPassword = email => dispatch => {
  backend()
    .post("user/requestPasswordChange", { email })
    .then(_ =>
      dispatch({
        type: FORGOT_PASSWORD,
        payload: {}
      })
    );
};


export const APP_INFO = "@@user/APP_INFO"
export const appInfo = () => dispatch => {
  backend()
    .get("user/app/info")
    .then(payload =>
      dispatch({
        type: APP_INFO,
        payload
      })
    );
}