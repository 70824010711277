import * as React from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router";
import { whoami, appInfo } from "./actions/userActions";
import ForgotPassword from "./components/ForgotPassword";
import ChangePassword from "./components/ChangePassword";
import LoginPage from "./components/LoginPage";
import MainPage from "./components/MainPage";
import ProfilePage from "./components/ProfilePage";
import UsersPage from "./components/UsersPage";

class Router extends React.Component {
  componentDidMount() {
    if (!this.props.isLogged && !this.isChangePassPage()) {
      this.props.whoami();
    }
    this.props.appInfo()
  }

  isChangePassPage = () => {
    return this.props.location.pathname.indexOf("change_password") !== -1;
  };

  isForgotPassPage = () => {
    return this.props.location.pathname.indexOf("forgot_password") !== -1;
  };

  isLoginPage = () => {
    return this.props.location.pathname.indexOf("login") !== -1;
  };

  getTree = () => (
    <Switch>
      <Route exact path="/" component={MainPage} />
      <Route exact path="/home" component={MainPage} />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/profile" component={ProfilePage} />
      <Route exact path="/users" component={UsersPage} />
      <Route exact path="/forgot_password" component={ForgotPassword} />
      <Route exact path="/change_password" component={ChangePassword} />
    </Switch>
  );

  render() {
    if (this.isLoginPage()) return this.getTree();
    if (this.isChangePassPage()) return this.getTree();
    if (this.isForgotPassPage()) return this.getTree();
    if (this.props.isFetching) return null;
    return this.getTree();
  }
}

export default connect(
  s => ({ isLogged: s.user.isLogged, isFetching: s.user.isFetching }),
  { whoami, appInfo }
)(withRouter(Router));
