import React, { Component } from "react";
import { Link } from "react-router-dom";
import { push } from "connected-react-router";
import "./LoginPage.css";
import bigLogo from "../images/big_logo.png";
import { connect } from "react-redux";
import { removeFromStorage } from "../util/storage";
import backend from "../util/backend";

class ProfilePage extends Component {
  state = {
    msg: null
  };

  logout = () => {
    removeFromStorage("token", "refreshToken", "expiresAt");
    this.props.push("/login");
  };

  componentDidMount() {
    this.setState({ ...this.props });
  }

  onSubmit = e => {
    e.preventDefault();
    const { firstName, lastName, email, password } = this.state;
    backend()
      .post("user/update", {
        firstName,
        lastName,
        email,
        password
      })
      .then(_ => this.setState({ msg: "Промените бяха запазени." }))
      .catch(_ =>
        this.setState({
          msg: "Промените не бяха запазени. Моля опитайте отново."
        })
      );
  };

  render() {
    return (
      <>
        <Link to="/">
          <div className="logo-holder">
          </div>
        </Link>

        <div className="login-strip" />
        <form onSubmit={this.onSubmit} className="login-wrapper">
          <button
            className="login-submit"
            onClick={this.logout}
            style={{ marginLeft: "5px", marginBottom: "20px" }}
          >
            Изход
          </button>
          <button className="login-submit" onClick={() => this.props.push("/")}>
            Начало
          </button>
          <input
            className="login-input"
            value={this.state.firstName}
            onChange={e => this.setState({ firstName: e.target.value })}
            placeholder="Име"
          />
          <input
            className="login-input"
            value={this.state.lastName}
            onChange={e => this.setState({ lastName: e.target.value })}
            placeholder="Фамилия"
          />
          <input
            className="login-input"
            value={this.state.email}
            onChange={e => this.setState({ email: e.target.value })}
            type="email"
            placeholder="Емайл"
          />
          <input
            className="login-input"
            value={this.state.password}
            minLength={5}
            onChange={e => this.setState({ password: e.target.value.trim() })}
            type="password"
            placeholder="Парола"
          />
          <div className="login-input" style={{ border: "none" }}>
            <button type="submit" className="login-submit">
              Запази
            </button>
          </div>
          <div>{this.state.msg}</div>
        </form>
      </>
    );
  }
}

export default connect(
  s => ({ ...s.user }),
  { push }
)(ProfilePage);
