import axios from "axios";
import { push } from "connected-react-router";
import { getAccessToken, removeFromStorage } from "./storage";

axios.interceptors.response.use(
  response => {
    console.log("response");
    return response;
  },
  function(error) {
    console.log("error");
    if (error.response.status in [401, 403]) {
      removeFromStorage("token", "refreshToken", "expiresAt");
      push("/login");
    }
    return Promise.reject(error.response);
  }
);

/**
 * Wrapper around axios.
 * Returns by default a json object after
 * all requests except HEAD.
 * Neatly logs all errors in development.
 *
 * @class BackendEndpoint
 */
class BackendEndpoint {
  http = axios.create({
    baseURL: "/api",
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    },
    // validateStatus: statusNumber => {
    //   if (statusNumber === 401 || statusNumber === 403) {
    //     window.location.href = "/login"
    //     return false;
    //   }
    //   if (statusNumber === 500) return false;
    //   return true;
    // }
  });

  instance = () => this.http;

  get = (url, config = {}) => {
    return this.http.get(url, config).then(r => r.data);
  };

  request = (config = {}) => {
    return this.http.request(config);
  };

  delete = (url, config = {}) => {
    return this.http.delete(url, config).then(r => r.data);
  };

  head = (url, config = {}) => {
    return this.http.head(url, config);
  };

  post = (url, data = {}, config = {}) => {
    return this.http.post(url, data, config).then(r => r.data);
  };

  put = (url, data = {}, config = {}) => {
    return this.http.put(url, data, config).then(r => r.data);
  };
  patch = (url, data = {}, config = {}) => {
    return this.http.patch(url, data, config).then(r => r.data);
  };
}

export default () => new BackendEndpoint();
